import axios from "axios";

export default axios.create({
    //baseURL: "https://localhost:5000",

    baseURL: "https://tickets.lpadesignstudios.com:5000",   // address of the backend
    headers: {
        "Content-type": "application/json"
    }
});
