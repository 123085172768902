import React, { Component } from "react";
// import AccountDataService from "../services/account-activities";
import MonitoringDataService from '../../services/monitoring-activities';
import HardwareAssetDataService from '../../services/hardware-asset-activities';
import { Redirect } from "react-router-dom";

import { connect } from 'react-redux';
import { ACCESSTOKEN, SET_MONITORING_TENANT_VIEWING, LOGOUT } from '../../features/actions/Is-Logged-Actions';
import { UPDATE_ACTIVE_LINK } from '../../features/actions/Active-Nav-Link-Actions';

import { Motion, spring } from "react-motion";

import { RingProgress, Text, SimpleGrid, Paper, Center, Group, rem } from '@mantine/core';
import {
    Progress,
    Box,
    Table,
    TextInput,
    Grid,
    keys,
    UnstyledButton,
    Container,
    Flex,
    Button
} from '@mantine/core';
import classes from '../TableSort.module.css';

import Navbar from '../../components/Navbar';

import { IconSelector, IconChevronDown, IconChevronUp, IconSearch } from '@tabler/icons-react';

//import Card from 'react-bootstrap/Card';

//import {Link} from 'react-router-dom';

//import ListGroup from 'react-bootstrap/ListGroup';

//import ChartWidget from '../Chart-Widget';       // WILL END UP BEING SOMETHING DIFFERENT FOR ADMIN


function Th(props) {
    const Icon = props.sorted ? (props.reversed ? IconChevronUp : IconChevronDown) : IconSelector;
    return (
        <Table.Th className={classes.th}>
            <UnstyledButton onClick={props.onSort} className={classes.control}>
                <Group justify="space-between">
                    <Text fw={500} fz="sm">
                        {props.children}
                    </Text>
                    <Center className={classes.icon}>
                        <Icon style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
                    </Center>
                </Group>
            </UnstyledButton>
        </Table.Th>
    );
}

function filterData(data, search) {
    console.log("filterData", data)
    const query = search.toLowerCase().trim();
    return data.filter((item) =>
        keys(data[0]).some((key) => item[key].toLowerCase().includes(query))
    );
}

function sortData(
    data,
    payload
) {
    const { sortBy } = payload;
    console.log("sortData", data)

    if (!sortBy) { 
        return filterData(data, payload.search);
    }

    return filterData(
        [...data].sort((a, b) => {
            if (!a[sortBy] || !b[sortBy]) return 0;
            if (payload.reversed) {
                return b[sortBy].localeCompare(a[sortBy]);
            }

            return a[sortBy].localeCompare(b[sortBy]);
        }),
        payload.search
    );
}

class Monitoring extends Component {

    constructor(props) {
        super(props);

        this.getTaniumChartData = this.getTaniumChartData.bind(this);
        this.getOSVersions = this.getOSVersions.bind(this);
        this.getOSCounts = this.getOSCounts.bind(this);
        this.setSorting = this.setSorting.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.getAssetInfo = this.getAssetInfo.bind(this);
        this.getLastSeen = this.getLastSeen.bind(this);
        this.getLowDiskSpace = this.getLowDiskSpace.bind(this);


        this.state = {
            // iFrameURLList: [
            //     "https://www.openstreetmap.org/export/embed.html?bbox=-0.004017949104309083%2C51.47612752641776%2C0.00030577182769775396%2C51.478569861898606&layer=mapnik",
            //     "https://www.youtube.com/embed/dXBohfjc4WA",
            //     "https://www.youtube.com/embed/dXBohfjc4WA",
            //     "https://www.youtube.com/embed/dXBohfjc4WA",
            //     "https://www.youtube.com/embed/dXBohfjc4WA",
            //     "https://www.youtube.com/embed/dXBohfjc4WA",
            //     "https://www.youtube.com/embed/dXBohfjc4WA"
            // ],
            segments: <Progress.Section></Progress.Section>,
            descriptions: <Box></Box>,
            total: 1,
            lowDiskSpace: 0,
            lastSeen: 0,
            search: "",
            sortedData: [],
            data: [],
            sortBy: 'name',
            reverseSortDirection: false,
            assetInfo: [],
            tenants: [],     // [{id: 1, name_of_tenant: "LPA"}]
            osVersions: [],      // [{osName: "Windows 10 Home", count: 1}, {...}]
            osCounts: [],    // [{osPlatform: "Windows", count: 4},  {osPlatform: "Linux", count: 14}, {...}]
            tenants: [],     // used to display buttons at top of page for switching tenant being viewed (only authorized tenants in this list based on user asking)
            
            selectedTenant: "",      // name of tenant user has selected from button list
            selectedTenantId: 0,        // id associated with the tenant name selected in the button
            cursor: 0,     // cursor gives the number provided through 1 minus results per page (ie: give 0 - get 0 - 49 -> the list is 0 indexed)will contain value of resultsPerPage * selectedPageButton (they start on page 1 on page load, so get first records)
            hasNextPage: true,
            resultsPerPage: 50,
            endpointTotal: 0,
            selectedPageButton: 1,

            pageButtons: [],     // will contain list of integers with length number of pages needed to view all assets
            searchText: "",
            typingTimeout: 0,       // We need to wait for delay before sending search query to backend

        }
    }

    setSorting(field) {
        //const reversed = field === this.state.sortBy ? !this.state.reverseSortDirection : false;
        let reversed;

        if(this.state.reverseSortDirection === false) {
            reversed = true;
            this.setState({
                reverseSortDirection: true
            });
        }

        else {
            reversed = false;
            this.setState({
                reverseSortDirection: false
            });
        }

        //console.log("Going to sort by: ", field);
        //const search = this.state.search;
        //console.log("this.state.data", this.state.data)
        //console.log(sortData(this.state.data, { sortBy: field, reversed, search }))
        this.setState({
            reverseSortDirection: reversed,
            sortBy: field,
            selectedPageButton: 1,
            cursor: 0
            //sortedData: this.state.data
        }, () => this.autoRefresh());
    };

    handleSearchChange(e) {

        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }

        this.setState({
            searchText: e.target.value,

            // resetOffsetTimeout: setTimeout(() => {
            //     this.resetOffset();   // reset the offset in state back to 0 (they have changed their search criteria so new list) - grabbing new tickets w/in this fxn
            // }, 1500),

            typingTimeout: setTimeout(() => {
                // call backend with updated filters for new results
                //this.grabUpdatedUserList();
                //console.log("Would be searching with text: ", this.state.searchText);
                // this is a new set of query results, so reset the page button selected and cursor
                this.setState({
                    selectedPageButton: 1,
                    cursor: 0
                }, () => this.autoRefresh());
                //this.autoRefresh();

            }, 1500)
        });
        // const { value } = event.currentTarget;
        // const sortBy = this.state.sortBy;
        // const reverseSortDirection = this.state.reverseSortDirection;
        // this.setState({
        //     search: value,
        //     sortedData: this.state.data
        // });
    };

    handlePageButtonChange(num) {

        this.setState({
            selectedPageButton: num,
            cursor: (num - 1) * this.state.resultsPerPage 
        }, () => this.autoRefresh());
    }

    componentDidMount() {
        this.props.UPDATE_ACTIVE_LINK("monitoring");

        if (this.props.loggedStatus.loggedIn) {
            this.props.onAccessTokenRequest(this.props.loggedStatus.username).then((token) => {
                // if the token can't be refreshed, force a logout
                if (token === undefined || token === null || token === "") {
                    console.log("Token was expired and can't refresh, so force logging out this user!");
                    this.props.LOGOUT();        // logout of global state
                    this.props.onLogout(this.props.loggedStatus.username);      // logout from Microsoft
                }

                else {
                    this.props.ACCESSTOKEN(token);
                    HardwareAssetDataService.getTenants(token, this.props.loggedStatus.id).then(response => {
                        console.log(response);
                        this.setState({
                            tenants: response.data.results
                        }, () => {
                            //this.setReportTenant(this.state.tenants);   // update the global state with the tenant selected

                            // if this is first time landing on this page, we need to update the global state and local one
                            if(this.props.loggedStatus.monitoring_tenant_name_selected === "") {

                                if(response.data.results && response.data.results.length > 0) {
                                    this.props.SET_MONITORING_TENANT_VIEWING({monitoring_tenant_id_selected: response.data.results[0].id, monitoring_tenant_name_selected: response.data.results[0].name_of_tenant});
                                    this.setState({
                                        selectedTenant: response.data.results[0].name_of_tenant,
                                        selectedTenantId: response.data.results[0].id,
                                        cursor: (this.state.selectedPageButton - 1) * this.state.resultsPerPage
                                    }, () => this.autoRefresh());
                                }
                            }
                            
                            else {
                                // we've landed on this page before, so set the local state to the global one

                                this.setState({
                                    selectedTenant: this.props.loggedStatus.monitoring_tenant_name_selected,
                                    selectedTenantId: this.props.loggedStatus.monitoring_tenant_id_selected,
                                    cursor: (this.state.selectedPageButton - 1) * this.state.resultsPerPage
                                }, () => this.autoRefresh());
                            }
                        });
                    })
                    .catch(e => {
                        console.log(e);
                    });
                    
                }
            });
        }

    }


    // this fxn keeps track of all data subset function calls so it's all stored in one place
    getTaniumChartData(tenantId, tenantName, askerId, cursor, hasNextPage, resultsPerPage, token) {
        this.getOSVersions(tenantId, tenantName, askerId, token);
        this.getOSCounts(tenantId, tenantName, askerId, token);
        this.getLowDiskSpace(tenantId, tenantName, askerId, token);
        this.getLastSeen(tenantId, tenantName, askerId, token);
        this.getAssetInfoForTable(tenantId, tenantName, askerId, cursor, hasNextPage, resultsPerPage, token);
    }


    // get asset info for the display table
    // returns an array of objects (note all values are strings) - each "page" will have 50 assets returned
    /*
        cursor: 'string',       // determines the next set of records to be returned in pagination
        hasNextPage: True,
        Data: [
            {
                name: data.node.name,
                make: data.node.manufacturer,
                model: data.node.model,
                serialNumber: data.node.serialNumber,
                ipAddress: data.node.ipAddress,
                natIpAddress: data.node.discover.natIpAddress,
                lastUser: data.node.lastLoggedInUser,
                freeDiskSpace: freeDiskSpace.free
            },
            {...},
            {...}
        ]
    
    */
    getAssetInfoForTable(tenantId, tenantName, askerId, cursor, hasNextPage, resultsPerPage, token) {

        // check the sort order information before sending request to backend
        let sortOrder;
        if(this.state.reverseSortDirection === false) {
            // they want the results ASC
            sortOrder = "ASC";
        }

        else {
            sortOrder = "DESC";
        }

        let sortBy = this.state.sortBy;

        let searchText = this.state.searchText;

        MonitoringDataService.getAssetInfoForTable(tenantId, tenantName, askerId, cursor, hasNextPage, resultsPerPage, sortOrder, sortBy, searchText, token).then(response => {
            console.log(response.data.data)
            //const search = this.state.search;
            //const reverseSortDirection = this.state.reverseSortDirection;
            //const sortBy = this.state.sortBy;
            this.setState({
                assetInfo: response.data.data,
                cursor: response.data.cursor,
                hasNextPage: response.data.hasNextPage,
                endpointTotal: response.data.endpointTotal,
                data: response.data.data,
                sortedData: response.data.data
            }, () => this.createPageButtonsArray(this.state.resultsPerPage, this.state.endpointTotal));
        })
            .catch(e => {
                console.log(e);
            });
    }

    // get data on the number of endpoints that are running out of disk space (low disk space is considered < 10% remaining)
    // returns a standard integer
    getLowDiskSpace(tenantId, tenantName, askerId, token) {
        MonitoringDataService.getLowDiskSpaceCount(tenantId, tenantName, askerId, token).then(response => {
            this.setState({
                lowDiskSpace: response.data
            });
        })
            .catch(e => {
                console.log(e);
            });
    }


    // get data on the number of endpoints that haven't checked in in 24 hours
    // returns a standard integer
    getLastSeen(tenantId, tenantName, askerId, token) {
        MonitoringDataService.getLastSeen(tenantId, tenantName, askerId, token).then(response => {
            this.setState({
                lastSeen: response.data
            });
        })
            .catch(e => {
                console.log(e);
            })
    }

    // call Tanium API and get the OS Versions and their device counts
    getOSVersions(tenantId, tenantName, askerId, token) {
        MonitoringDataService.getOSVersions(tenantId, tenantName, askerId, token).then(response => {
            // data returned as [{osName: "Windows 10 Home", count: 1}, {...}]
            console.log(response);

            this.setState({
                osVersions: response.data
            });
        })
            .catch(e => {
                console.log(e);
            });
    }

    // getLowDiskSpace(tenantId, tenantName, askerId, token) {
    //     MonitoringDataService.getLowDiskSpace(tenantId, tenantName, askerId, token).then(response => {
    //         console.log(response);

    //         this.setState({
    //             lowDiskSpace: response.data
    //         });
    //     })
    //         .catch(e => {
    //             console.log(e);
    //         });
    // }

    // getLastSeen(tenantId, tenantName, askerId, token) {
    //     MonitoringDataService.getLastSeen(tenantId, tenantName, askerId, token).then(response => {
    //         console.log(response);

    //         this.setState({
    //             lastSeen: response.data
    //         });
    //     })
    //         .catch(e => {
    //             console.log(e);
    //         });
    // }

    getAssetInfo(tenantId, askerId, token) {
        MonitoringDataService.getAssetInfo(tenantId, askerId, token).then(response => {
            console.log(response.data);

            const search = this.state.search;
            const reverseSortDirection = this.state.reverseSortDirection;
            const sortBy = this.state.sortBy;
            this.setState({
                data: response.data,
                sortedData: response.data
            });
        })
            .catch(e => {
                console.log(e);
            });
    }

    // call Tanium API and get the count of each OS platform (number of Windows devices, vs Linux, etc.)
    getOSCounts(tenantId, tenantName, askerId, token) {
        MonitoringDataService.getOSCounts(tenantId, tenantName, askerId, token).then(response => {
            // data returned as [{osPlatform: "Windows", count: 4},  {osPlatform: "Linux", count: 14}, {...}]
            console.log(response);
            const colors = ["blue", "red", "green", "cyan"];
            var colorIndex = 0;
            var total = 0;
            response.data.forEach((r) => { total += r.count; });

            var s = [];
            var d = [];
            response.data.forEach((r) => {
                var color = colors[colorIndex++];
                var part = (r.count / total) * 100;

                s.push(<Progress.Section value={part} color={color} key={color}>
                    {part > 10 && <Progress.Label>{Math.round(part)}%</Progress.Label>}
                </Progress.Section>);

                d.push(
                    <Box key={r.osPlatform} style={{ borderBottomColor: color }}>
                        <Text tt="uppercase" fz="xs" c="dimmed" fw={700}>
                            {r.osPlatform}
                        </Text>

                        <Group justify="space-between" align="flex-end" gap={0}>
                            <Text fw={700}>{r.count}</Text>
                            <Text c={color} fw={700} size="sm">
                                {Math.round(part)}%
                            </Text>
                        </Group>
                    </Box>);
            })

            this.setState({
                total: total,
                segments: s,
                descriptions: d
            });
        })
            .catch(e => {
                console.log(e);
            });
    }

    // NOT CURRENTLY IN USE
    getiFrameUrls(tenant_id, token) {
        MonitoringDataService.get(tenant_id, this.props.loggedStatus.id, token).then(response => {
            console.log(response);
        })
            .catch(e => {
                console.log(e);
            });
    }

    handleMonitoringTenantChange(name_of_tenant) {
        
        // if this is a new selection, update the global state
        if(name_of_tenant !== this.state.selectedTenant) {
            let newTenantId = this.state.tenants.find(o => o.name_of_tenant === name_of_tenant).id;
            this.props.SET_MONITORING_TENANT_VIEWING({monitoring_tenant_id_selected: newTenantId, monitoring_tenant_name_selected: name_of_tenant});

            // this is new tenant selection so ask for data
            this.setState({
                selectedTenant: name_of_tenant,
                selectedTenantId: newTenantId,
                cursor: 0,       // reset the cursor to the backend
                selectedPageButton: 1   // reset the page selected
            }, () => this.autoRefresh());
        }
    }

    // this fxn currently makes sure token is fresh and calls getTaniumChartData - not currently on auto timer, but may change
    autoRefresh() {
        console.log("auto refresh tanium data");
        this.props.onAccessTokenRequest(this.props.loggedStatus.username).then((token) => {

            // if the token can't be refreshed, force a logout
            if (token === undefined || token === null || token === "") {
                console.log("Token was expired and can't refresh, so force logging out this user!");
                this.props.LOGOUT();        // logout of global state
                this.props.onLogout(this.props.loggedStatus.username);      // logout from Microsoft
            }

            else {
                this.props.ACCESSTOKEN(token);

                // now have new Cumulus token, so get next stuff for chart
                this.getTaniumChartData(
                    this.state.selectedTenantId, 
                    this.state.selectedTenant, 
                    this.props.loggedStatus.id, 
                    this.state.cursor, 
                    this.state.hasNextPage, 
                    this.state.resultsPerPage, 
                    token
                );
                /* HardwareAssetDataService.getTenants(token, this.props.loggedStatus.id).then(response => {
                    console.log(response);

                    this.setState({
                        tenants: response.data.results
                    }, () => {
                        if (this.state.tenants && this.state.tenants.length > 0) {
                            //this.getiFrameUrls(this.state.tenants[0].id, this.props.loggedStatus.accessToken);

                            // call the Tanium endpoints to get the data needed for charts/graphs
                            this.getTaniumChartData(this.state.tenants[0].id, this.props.loggedStatus.id, this.state.cursor, this.state.hasNextPage, this.state.resultsPerPage, this.props.loggedStatus.accessToken);
                        }

                        else {
                            console.log("No tenants to view");
                        }
                    });
                })
                    .catch(e => {
                        console.log(e);
                    }); */
            }
        });

        // uncomment this to turn auto refresh back on
        /*this.timer = setTimeout(() => {

            this.autoRefresh()

        }, 60000)*/
    }

    // NOT CURRENTLY IN USE
    //arrayChunk = (arr, n) => {
    //const array = arr.slice();
    //const chunks = [];
    //while (array.length) chunks.push(array.splice(0, n));
    // return chunks;
    // };

    // onChangeName(e) {
    //     this.setState({
    //         name: e.target.value
    //     });
    // }

    // saveAccount() {
    //     var data = {
    //         name: this.state.name
    //     };

    //     if(this.props.loggedStatus.loggedIn){
    //         this.props.onAccessTokenRequest(this.props.loggedStatus.username).then((token) => {
    //         this.props.ACCESSTOKEN(token);
    //         AccountDataService.create(data, token)
    //         .then(response => {
    //             this.props.history.push('/accounts');
    //         //console.log(response.data);
    //         // this.setState({
    //         //     redirect: true
    //         // });
    //         })
    //         .catch(e => {
    //             console.log(e);
    //         });
    //         });
    //     }



    // AccountDataService.create(data)
    // .then(response => {
    //     //console.log(response.data);
    //     // this.setState({
    //     //     redirect: true
    //     // });
    // })
    // .catch(e => {
    //     console.log(e);
    // });
    //}

    createPageButtonsArray(numPerPage, total) {
        let buttons = [];
        let numButtons = Math.ceil(total/numPerPage);
        console.log("Number of buttons should be = ", numButtons)
        for(let i = 1; i <= numButtons; i++) {
            buttons.push(i);
        }

        //return buttons;

        this.setState({
            pageButtons: buttons
        });
    }




    render() {
        if (!this.props.loggedStatus.loggedIn) {
            return (
                <Redirect to='/' />
            );
        }
        //console.log("assetInfo", this.state.assetInfo)
        const rows = this.state.assetInfo.map((row) => (
            <Table.Tr key={row.localIpAddress + row.name + row.serialNumber}>
                <Table.Td>{row.name}</Table.Td>
                <Table.Td>{row.localIpAddress}</Table.Td>
                <Table.Td>{row.model}</Table.Td>
                <Table.Td>{row.freeDiskSpace}</Table.Td>
                <Table.Td>{row.serialNumber}</Table.Td>
            </Table.Tr>
        ));
        //console.log("Rows", rows)
        //let buttonArray = this.createPageButtonsArray(this.state.resultsPerPage, this.state.endpointTotal);

        // const pageButtons = buttonArray.map((num) => (
        //     <Button 
        //         size="compact-xs"
        //         variant={this.state.selectedPageButton === num ? "filled" : "default"}
        //         onClick={() => this.handlePageButtonChange(num)}
        //     >
        //         {num}
        //     </Button>
        // ));


        return (
            <>
                <Navbar pageTitle="Monitoring" />
                <Container fluid pt="20px" >
                    <Paper withBorder p="md" radius="md" w="100%" mb="sm" display="inline-block">
                        <Flex
                            justify="flex-start"
                            align="flex-start"
                            direction="row"
                            gap="md"
                        >
                            {this.state.tenants && this.state.tenants.map((tenant) => (
                                <Button
                                    h="36px"
                                    variant={this.state.selectedTenant === tenant.name_of_tenant ? "filled" : "default"}
                                    onClick={() => this.handleMonitoringTenantChange(tenant.name_of_tenant)}
                                >
                                    {tenant.name_of_tenant}
                                </Button>))}
                        </Flex>
                    </Paper>
                    <Grid gutter="sm">
                        <Grid.Col span={6}>
                            <Paper withBorder p="md" radius="md" w="100%" h="240px" display="inline-block">
                                <Group justify="space-between">
                                    <Group align="flex-end" gap="xs">
                                        <Text fz="xl" fw={700}>
                                            {this.state.total} Endpoints
                                        </Text>
                                    </Group>
                                </Group>

                                <Text c="dimmed" fz="sm">
                                    OS Type
                                </Text>

                                <Progress.Root size={34} mt={40}>
                                    {this.state.segments}
                                </Progress.Root>
                                <SimpleGrid cols={{ base: 1, xs: 3 }} mt="xl">
                                    {this.state.descriptions}
                                </SimpleGrid>
                            </Paper>
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <Paper withBorder radius="md" p="15px" key={"lowDiskSpace"} h="115px" w="100%" mb="10px" display="inline-block" style={{ verticalAlign: "top" }}>
                                <Group>
                                    <Motion defaultStyle={{ x: 0 }} style={{ x: spring((this.state.lowDiskSpace / (this.state.total === 0 ? 1 : this.state.total)) * 100) }}>
                                        {({ x }) => <RingProgress
                                            size={80}
                                            roundCaps
                                            thickness={8}
                                            sections={[{ value: x, color: ((this.state.lowDiskSpace / this.state.total) > 0.8 ? "red" : "blue") }]}
                                            label={
                                                <Center>
                                                    {Math.round((this.state.lowDiskSpace / this.state.total) * 100)}%
                                                </Center>
                                            }
                                        />
                                        }
                                    </Motion>

                                    <div>
                                        <Text c="dimmed" size="xs" tt="uppercase" fw={700}>
                                            {"endpoints with low disk space"}
                                        </Text>
                                        <Text fw={700} size="xl">
                                            {this.state.lowDiskSpace}
                                        </Text>
                                    </div>
                                </Group>
                            </Paper>
                            <Paper withBorder radius="md" p="15px" key={"lastSeen"} h="115px" w="100%" display="inline-block" style={{ verticalAlign: "top" }}>
                                <Group>
                                    <Motion defaultStyle={{ x: 0 }} style={{ x: spring((this.state.lastSeen / (this.state.total === 0 ? 1 : this.state.total)) * 100) }}>
                                        {({ x }) => <RingProgress
                                            size={80}
                                            roundCaps
                                            thickness={8}
                                            sections={[{ value: x, color: ((this.state.lastSeen / this.state.total) > 0.8 ? "red" : "blue") }]}
                                            label={
                                                <Center>
                                                    {Math.round((this.state.lastSeen / this.state.total) * 100)}%
                                                </Center>
                                            }
                                        />
                                        }
                                    </Motion>

                                    <div>
                                        <Text c="dimmed" size="xs" tt="uppercase" fw={700}>
                                            {"endpoints not seen in the last 24 hours"}
                                        </Text>
                                        <Text fw={700} size="xl">
                                            {this.state.lastSeen}
                                        </Text>
                                    </div>
                                </Group>
                            </Paper>
                        </Grid.Col>
                    </Grid>
                    <Paper withBorder p="md" radius="md" display="inline-block" mt="15px">
                        <TextInput
                            placeholder="Search by any field"
                            mb="md"
                            leftSection={<IconSearch style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
                            value={this.state.searchText}
                            onChange={this.handleSearchChange}
                        />
                        
                        <Flex
                            justify="flex-start"
                            align="flex-start"
                            direction="row"
                            gap="md"
                        >
                            {this.state.pageButtons && this.state.pageButtons.map((num) => (
                                <Button 
                                    size="compact-xs"
                                    variant={this.state.selectedPageButton === num ? "filled" : "default"}
                                    onClick={() => this.handlePageButtonChange(num)}
                                >
                                    {num}
                                </Button>
                            ))}
                        </Flex>

                        <div style={{marginTop: "20px"}}></div>
                        
                        <Table horizontalSpacing="md" verticalSpacing="xs" miw={700} layout="fixed">
                            <Table.Thead>
                                <Table.Tr>
                                    <Th
                                        sorted={this.state.sortBy === 'name'}
                                        reversed={this.state.reverseSortDirection}
                                        onSort={() => this.setSorting('name')}
                                    >
                                        <div className={this.state.sortBy === 'name' ? "activeMyAssignedTicketsHeader" : ""}>Name</div>
                                    </Th>
                                    <Th
                                        sorted={this.state.sortBy === 'ipAddress'}
                                        reversed={this.state.reverseSortDirection}
                                        onSort={() => this.setSorting('ipAddress')}   
                                    >
                                        <div className={this.state.sortBy === 'ipAddress' ? "activeMyAssignedTicketsHeader" : ""}>IP Address</div>
                                    </Th>
                                    <Th
                                        sorted={this.state.sortBy === 'model'}
                                        reversed={this.state.reverseSortDirection}
                                        onSort={() => this.setSorting('model')}
                                    >
                                        <div className={this.state.sortBy === 'model' ? "activeMyAssignedTicketsHeader" : ""}>Model</div>
                                    </Th>
                                    <Th
                                        sorted={this.state.sortBy === 'freeDiskSpace'}
                                        reversed={this.state.reverseSortDirection}
                                        onSort={() => this.setSorting('freeDiskSpace')}
                                    >
                                        <div className={this.state.sortBy === 'freeDiskSpace' ? "activeMyAssignedTicketsHeader" : ""}>Free Disk Space</div>
                                    </Th>
                                    <Th
                                        sorted={this.state.sortBy === 'serialNumber'}
                                        reversed={this.state.reverseSortDirection}
                                        onSort={() => this.setSorting('serialNumber')}
                                    >
                                        <div className={this.state.sortBy === 'serialNumber' ? "activeMyAssignedTicketsHeader" : ""}>Serial Number</div>
                                    </Th>
                                </Table.Tr>
                            </Table.Thead>
                            <Table.Tbody>
                                {rows.length > 0 ? (
                                    rows
                                ) : (
                                    <Table.Tr>
                                        <Table.Td>
                                            <Text fw={500} ta="center">
                                                Nothing found
                                            </Text>
                                        </Table.Td>
                                    </Table.Tr>
                                )}
                            </Table.Tbody>
                        </Table>
                    </Paper>
                </Container>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedStatus: state.logged,
        activeNavLinkStatus: state.activeNav,
        userPermissions: state.userPermissions
    };
}

export default connect(mapStateToProps, { UPDATE_ACTIVE_LINK, ACCESSTOKEN, SET_MONITORING_TENANT_VIEWING, LOGOUT })(Monitoring);