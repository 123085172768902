import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import UserDataService from '../services/user-activities';
import { ACCESSTOKEN, USER_DETAILS, LOGOUT } from '../features/actions/Is-Logged-Actions';
import { IconChevronsDown } from '@tabler/icons-react';
import {
    Text,
} from '@mantine/core';
import ToggleColorSchemeSwitch from "./Sidebar/ToggleColorSchemeSwitch";

import logo from "../images/Cumulus_Logo-Gray.png";

import { TextInput, ActionIcon, useMantineTheme, rem } from '@mantine/core';
import { IconSearch, IconArrowRight } from '@tabler/icons-react';

export function InputWithButton(props) {
  const theme = useMantineTheme();

  return (
    <TextInput
      radius="xl"
      size="md"
      mt="18px"
      mr="18px"
      ml="auto"
      placeholder="Search questions"
      rightSectionWidth={42}
      leftSection={<IconSearch style={{ width: rem(18), height: rem(18) }} stroke={1.5} />}
      rightSection={
        <ActionIcon size={32} radius="xl" color={theme.primaryColor} variant="filled">
          <IconArrowRight style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
        </ActionIcon>
      }
      {...props}
    />
  );
}

//import { LOGOUT } from '../features/actions/Is-Logged-Actions';

class Navbar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userExists: "",
            userDetails: {},
            errorMessage: ""        // Will display any errors returned by the API call
        };
    }

    componentDidMount() {
    }

    convertPageTitle(windowLocation) {
        var formattedLocation = "";
        console.log(windowLocation);

        windowLocation = windowLocation.replace("hardwareTickets", "knowledgebase");
        windowLocation = windowLocation.replace("userHome", "dashboard");

        // Ignore first character because its always /
        for (var i = 1; i < windowLocation.length; i++) {
            var letter = windowLocation[i];
            if (i === 1) {
                letter = letter.toUpperCase();
            }
            if (letter == letter.toUpperCase() && letter != letter.toLowerCase()) {
                formattedLocation += " ";
            }
            if (letter == "/") {
                letter = " - ";
            }
            formattedLocation += letter;
        }
        return formattedLocation;
    }

    render() {
        const isLoggedIn = this.props.loggedStatus.loggedIn;

        let username;

        //if(!this.state.userExists) {
        if (this.props.loggedStatus.fullName === 'undefined undefined' || this.props.loggedStatus.fullName === '') {
            username = "Account";
        }

        else {
            username = this.props.loggedStatus.fullName;
            //    if(this.state.userDetails.middle_name === null) {
            //         //username = this.props.userDetails.first_name + " " + this.props.userDetails.last_name;
            //    }

            //    else {
            //         username = this.state.userDetails.first_name + " " + this.state.userDetails.middle_name + " " + this.state.userDetails.last_name;
            //    }
        }

        const location = this.convertPageTitle(window.location.pathname);

        return (
            <div style={{
                position: "fixed",
                top: "0",
                left: "0",
                width: "100%",
                zIndex: "5",
                height: "80px",
                paddingLeft: "80px",
                backgroundColor: "var(--mantine-color-default)",
                display: "flex",
                borderBottom: "1px solid var(--mantine-color-blue-6)",
                color: "var(--mantine-color-text)"
            }}>
                <IconChevronsDown style={{ height: "40px", margin: "20px" }} />
                <Text lh="77px" fz="20px">{this.props.pageTitle ? this.props.pageTitle : location}</Text>
                {this.props.children}
                <ToggleColorSchemeSwitch />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedStatus: state.logged
    };
}

export default connect(mapStateToProps, { ACCESSTOKEN, USER_DETAILS, LOGOUT })(Navbar);