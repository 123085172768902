import React, { Component } from "react";
// import Switch from "react-switch";

// import AccountDataService from "../services/account-activities";
//import { Link } from "react-router-dom";
import { connect } from 'react-redux';

import { Redirect } from 'react-router';

import { Link } from 'react-router-dom';

import Navbar from '../components/Navbar';

import Container from 'react-bootstrap/Container';
import {
    Table,
    ScrollArea,
    UnstyledButton,
    Group,
    Text,
    Center,
    TextInput,
    rem,
    keys,
    Button,
    Paper,
    Select,
    Grid,
    Flex,
    Tooltip
} from '@mantine/core';
import { IconSelector, IconChevronDown, IconChevronUp, IconSearch, IconFilterX } from '@tabler/icons-react';
import classes from './TableSort.module.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";

import { UPDATE_ACTIVE_LINK } from '../features/actions/Active-Nav-Link-Actions';
import { ACCESSTOKEN, LOGOUT } from '../features/actions/Is-Logged-Actions';

import { ASSIGNMENT_CHANGE_RESOLVED, STATUS_CHANGE_RESOLVED, TYPE_CHANGE_RESOLVED, PRIORITY_CHANGE_RESOLVED, RESET_FILTERS, UPDATE_ACTIVE_ASSIGNMENT, UPDATE_SEARCH_TEXT, UPDATE_SORT_BY, UPDATE_SORT_ORDER } from '../features/actions/My-Assigned-Tickets-Actions';

import "bootstrap/dist/css/bootstrap.min.css";
//import { Dropdown } from "react-bootstrap";
//import DropdownButton from 'react-bootstrap/DropdownButton';
//import Dropdown from 'react-bootstrap/Dropdown';

import AssignedTechSelector from "./Assigned-Tech-Selector";
import StatusSelector from "./My-Assigned-Tickets-Status-Selector";
import TypeSelector from "./My-Assigned-Tickets-Type-Selector";
import PrioritySelector from "./My-Assigned-Tickets-Priority-Selector";

import MyAssignedTicketsService from '../services/my-assigned-tickets-activities';
import HardwareTicketDataService from '../services/hardware-ticket-activities';

const lateTicketThreshold = 15;     // (Currently represents number of minutes) Represents the number beyond which a ticket is considered to have a late response. determines color of text in time since created column.

function Th(props) {
    const Icon = props.sorted ? (props.reversed ? IconChevronUp : IconChevronDown) : IconSelector;
    return (
        <Table.Th className={classes.th}>
            <UnstyledButton onClick={props.onSort} className={classes.control}>
                <Group justify="space-between">
                    <Text fw={500} fz="sm">
                        {props.children}
                    </Text>
                    <Center className={classes.icon}>
                        <Icon style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
                    </Center>
                </Group>
            </UnstyledButton>
        </Table.Th>
    );
}

function filterData(data, search) {
    const query = search.toLowerCase().trim();
    return data.filter((item) =>
        keys(data[0]).some((key) => item[key].toLowerCase().includes(query))
    );
}

function sortData(
    data,
    payload
) {
    const { sortBy } = payload;

    if (!sortBy) {
        return filterData(data, payload.search);
    }

    return filterData(
        [...data].sort((a, b) => {
            if (payload.reversed) {
                return b[sortBy].localeCompare(a[sortBy]);
            }

            return a[sortBy].localeCompare(b[sortBy]);
        }),
        payload.search
    );
}

class MyAssignedTickets extends Component {

    constructor(props) {
        super(props);

        this.onChangeSearchText = this.onChangeSearchText.bind(this);
        this.handleSortOrderSwap = this.handleSortOrderSwap.bind(this);
        this.getAssignedTickets = this.getAssignedTickets.bind(this);
        this.setSorting = this.setSorting.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);

        this.setTicketTimesSinceCreated = this.setTicketTimesSinceCreated.bind(this);

        this.scrollerRef = React.createRef();

        this.state = {
            searchText: "",      // The value user has typed into the search bar
            typingTimeout: 0,    // We need to wait for delay before sending search query to backend
            resetOffsetTimeout: 0,  // handles the delay in changing ticket offset to 0 while user is typing
            ticketOffsetValue: 0,   // the value passed to database to know how many records we've already retrieved by scrolling (increments by 10 each database call)
            numberOfRecordsToShow: 10,   // Will increment by 10 each time user scrolls to bottom of page
            allTicketsShown: false,  // Controls whether to ask for more records
            sortOrder: "asc",       // default sort order is desc (newest tickets first) ie: larger id #'s first
            reverseSortDirection: true,    // handles if the sorted carrot is up or down in table header - true = carrot up, false = carrot down
            sortBy: "date",      // the column selected for sorting will start with the time since created (this handles front end logic)
            tickets: [],         // the actual ticket details to display in the table
            rowCount: 0,         // TOTAL number of tickets possible for those search params
            ticket_slas: [],    // contains the ticket sla data input in the system (array of objects)
            getTicketMode: 2, //Controls how getAssignedTickets calls for new tickets -- 0 = Scroll, 1 = autoRefresh, 2 = first grab
            scrollUpdateComplete: true, //tells scroll event handler if it can call for more tickets
            search: "",
            sortedData: [],
            data: [],
        };
    }

    setSorting(field) {
        const reversed = field === this.state.sortBy ? !this.state.reverseSortDirection : false;
        const search = this.state.search;
        this.setState({
            reverseSortDirection: reversed,
            sortBy: field,
            sortedData: sortData(this.state.data, { sortBy: field, reversed, search })
        });
    };

    handleSearchChange(event) {
        console.log(event);
        const { value } = event.currentTarget;
        const sortBy = this.state.sortBy;
        const reverseSortDirection = this.state.reverseSortDirection;
        this.setState({
            search: value,
            sortedData: sortData(this.state.data, { sortBy, reversed: reverseSortDirection, search: value })
        });
    };

    // every time user clicks column header, it changes search criteria so resets ticket offset
    // flips asc to desc and v.v.
    // sets the name of the column sorted (will use the name of the ticket table column we will sort on to put directly into query)

    handleColumnSort(columnToSort) {
        if (this.state.sortOrder === "desc") {     // This will put the title column in ascending order
            this.props.UPDATE_SORT_ORDER("asc");    // globally set the value to asc
            switch (columnToSort) {
                case "title":
                    this.props.UPDATE_SORT_BY("title");
                    this.setState(
                        {
                            sortOrder: "asc",
                            sortBy: "title",
                            reverseSortDirection: true,
                            ticketOffsetValue: 0,
                            getTicketMode: 2,
                            tickets: []
                        },
                        () => this.getAssignedTickets()
                    );
                    break;
                case "status":
                    this.props.UPDATE_SORT_BY("status");
                    this.setState(
                        {
                            sortOrder: "asc",
                            sortBy: "status",
                            reverseSortDirection: true,
                            ticketOffsetValue: 0,
                            getTicketMode: 2,
                            tickets: []
                        },
                        () => this.getAssignedTickets()
                    );
                    break;
                case "priority":
                    this.props.UPDATE_SORT_BY("priority");
                    this.setState(
                        {
                            sortOrder: "asc",
                            sortBy: "priority",
                            reverseSortDirection: true,
                            ticketOffsetValue: 0,
                            getTicketMode: 2,
                            tickets: []
                        },
                        () => this.getAssignedTickets()
                    );
                    break;
                case "type":
                    this.props.UPDATE_SORT_BY("type");
                    this.setState(
                        {
                            sortOrder: "asc",
                            sortBy: "type",
                            reverseSortDirection: true,
                            ticketOffsetValue: 0,
                            getTicketMode: 2,
                            tickets: []
                        },
                        () => this.getAssignedTickets()
                    );
                    break;
                case "creator":
                    this.props.UPDATE_SORT_BY("creator");
                    this.setState(
                        {
                            sortOrder: "asc",
                            sortBy: "creator",
                            reverseSortDirection: true,
                            ticketOffsetValue: 0,
                            getTicketMode: 2,
                            tickets: []
                        },
                        () => this.getAssignedTickets()
                    );
                    break;
                case "date":
                    this.props.UPDATE_SORT_BY("date");
                    this.setState(
                        {
                            sortOrder: "asc",
                            sortBy: "date",
                            reverseSortDirection: true,
                            ticketOffsetValue: 0,
                            getTicketMode: 2,
                            tickets: []
                        },
                        () => this.getAssignedTickets()
                    );
                    break;
                default:
                    console.log("Bad Column Name Given");
            }

        }

        else if (this.state.sortOrder === "asc") { // This will put the title column in descending order
            this.props.UPDATE_SORT_ORDER("desc");    // globally set the value to desc
            switch (columnToSort) {
                case "title":
                    this.props.UPDATE_SORT_BY("title");
                    this.setState(
                        {
                            sortOrder: "desc",
                            sortBy: "title",
                            reverseSortDirection: false,
                            ticketOffsetValue: 0,
                            getTicketMode: 2,
                            tickets: []
                        },
                        () => this.getAssignedTickets()
                    );
                    break;
                case "status":
                    this.props.UPDATE_SORT_BY("status");
                    this.setState(
                        {
                            sortOrder: "desc",
                            sortBy: "status",
                            reverseSortDirection: false,
                            ticketOffsetValue: 0,
                            getTicketMode: 2,
                            tickets: []
                        },
                        () => this.getAssignedTickets()
                    );
                    break;
                case "priority":
                    this.props.UPDATE_SORT_BY("priority");
                    this.setState(
                        {
                            sortOrder: "desc",
                            sortBy: "priority",
                            reverseSortDirection: false,
                            ticketOffsetValue: 0,
                            getTicketMode: 2,
                            tickets: []
                        },
                        () => this.getAssignedTickets()
                    );
                    break;
                case "type":
                    this.props.UPDATE_SORT_BY("type");
                    this.setState(
                        {
                            sortOrder: "desc",
                            sortBy: "type",
                            reverseSortDirection: false,
                            ticketOffsetValue: 0,
                            getTicketMode: 2,
                            tickets: []
                        },
                        () => this.getAssignedTickets()
                    );
                    break;
                case "creator":
                    this.props.UPDATE_SORT_BY("creator");
                    this.setState(
                        {
                            sortOrder: "desc",
                            sortBy: "creator",
                            reverseSortDirection: false,
                            ticketOffsetValue: 0,
                            getTicketMode: 2,
                            tickets: []
                        },
                        () => this.getAssignedTickets()
                    );
                    break;
                case "date":
                    this.props.UPDATE_SORT_BY("date");
                    this.setState(
                        {
                            sortOrder: "desc",
                            sortBy: "date",
                            reverseSortDirection: false,
                            ticketOffsetValue: 0,
                            getTicketMode: 2,
                            tickets: []
                        },
                        () => this.getAssignedTickets()
                    );
                    break;
                default:
                    console.log("Bad Column Name Given");
            }
        }

    }

    componentDidMount() {

        console.log("Tech name chosen = ", this.props.myAssignedTickets.active_assignment_name);
        console.log("Tech id selected = ", this.props.myAssignedTickets.active_assignment_id);
        var queryParameters = new URLSearchParams(window.location.search);
        var search = queryParameters.get("search");
        if (search) {
            this.setState({ searchText: queryParameters.get("search") })
        }
        window.addEventListener('scroll', this.handleAssignedTableScroll, { passive: true })
        if (this.props.loggedStatus.loggedIn) {
            // Remove any state information from previous visits to this page
            //this.props.RESET_FILTERS();

            this.props.onAccessTokenRequest(this.props.loggedStatus.username).then((token) => {
                // if the token can't be refreshed, force a logout
                if (token === undefined || token === null || token === "") {
                    console.log("Token was expired and can't refresh, so force logging out this user!");
                    this.props.LOGOUT();        // logout of global state
                    this.props.onLogout(this.props.loggedStatus.username);      // logout from Microsoft
                }

                else {
                    this.props.ACCESSTOKEN(token);

                    // Set the default "Assigned To" to Unassigned per Douglas request (backend knows id -1 means look for null assigned records)

                    //this.props.UPDATE_ACTIVE_ASSIGNMENT({name: "Unassigned", id: -1});

                    // Set the current person they are filtering on to their own name

                    //this.props.UPDATE_ACTIVE_ASSIGNMENT({name: this.props.loggedStatus.fullName, id: this.props.loggedStatus.id});
                    this.setState({
                        searchText: this.props.myAssignedTickets.search_text_entered,
                        sortBy: this.props.myAssignedTickets.sort_by,
                        sortOrder: this.props.myAssignedTickets.sort_order,
                        reverseSortDirection: this.props.myAssignedTickets.sort_order === "asc" ? true : false

                    }, () => this.autoRefresh());
                    //this.autoRefresh();
                }


            });

            //this.autoRefresh()

            this.props.UPDATE_ACTIVE_LINK("adminPanelTicketHoldingArea");  // Tell global state that this is the active page now (controls nav bar highlighting)
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleAssignedTableScroll)
        clearTimeout(this.timer)
    }

    componentDidUpdate() {
        // if user updated the person they were looking at in the table, reset the change to false (this should only fire once per change)
        if (this.props.myAssignedTickets.assignment_change_detected || this.props.myAssignedTickets.status_change_detected || this.props.myAssignedTickets.type_change_detected || this.props.myAssignedTickets.priority_change_detected) {

            if (this.props.myAssignedTickets.assignment_change_detected) {
                //console.log("Picked up change in assigned to drop down");
                this.props.ASSIGNMENT_CHANGE_RESOLVED();
            }

            else if (this.props.myAssignedTickets.status_change_detected) {
                //console.log("Picked up change in status drop down");
                this.props.STATUS_CHANGE_RESOLVED();
            }

            else if (this.props.myAssignedTickets.type_change_detected) {
                //console.log("Picked up change in type drop down");
                this.props.TYPE_CHANGE_RESOLVED();
            }

            else if (this.props.myAssignedTickets.priority_change_detected) {
                //console.log("Picked up change in priority drop down");
                this.props.PRIORITY_CHANGE_RESOLVED();
            }


            this.props.onAccessTokenRequest(this.props.loggedStatus.username).then((token) => {
                // if the token can't be refreshed, force a logout
                if (token === undefined || token === null || token === "") {
                    console.log("Token was expired and can't refresh, so force logging out this user!");
                    this.props.LOGOUT();        // logout of global state
                    this.props.onLogout(this.props.loggedStatus.username);      // logout from Microsoft
                }

                else {
                    this.props.ACCESSTOKEN(token);
                    //this.retreiveNewEmailTickets(token).then(response => {
                    //this.setState({
                    //  ticketList: response.results
                    //});
                    // Set ticket offset value back to 0 (we've clicked a new filter so we start at the beginning again)
                    this.setState({
                        ticketOffsetValue: 0,
                        getTicketMode: 2,
                        tickets: []
                    }, () => this.getAssignedTickets());
                }

            });
        }

    }

    setTicketTimesSinceCreated(tickets) {
        for (var item of tickets) {
            if (item.years_since_sent > 0) {
                item.time_since_sent = item.years_since_sent + (item.years_since_sent > 1 ? "y" : "y");
            }

            else if (item.months_since_sent > 0) {
                item.time_since_sent = item.months_since_sent + (item.months_since_sent > 1 ? "mon" : "mon");
            }

            else if (item.days_since_sent > 0) {
                item.time_since_sent = item.days_since_sent + (item.days_since_sent > 1 ? "d" : "d");
            }

            else if (item.hours_since_sent > 0) {
                item.time_since_sent = item.hours_since_sent + (item.hours_since_sent > 1 ? "h" : "h");
            }

            else if (item.minutes_since_sent > 0) {
                item.time_since_sent = item.minutes_since_sent + (item.minutes_since_sent > 1 ? "min" : "min");
            }

            else {
                item.time_since_sent = "moments ago";
            }

            // check to see what color this needs to be highlighted in

            // if responded to time is null check if the minutes since sent exceeds the warning threshold
            if (item.time_of_response === null) {        // this ticket hasn't been responded to yet, so that's the threshold we're looking for
                // find the warning cutoff for responding for this item's SLA
                item.sla_info = null;
                if (item.sla_id !== null) {
                    item.sla_info = this.state.ticket_slas.find(e => e.id === item.sla_id);

                    //console.log("This ticket's sla warning cutoff values is: ", item.sla_info.warning_cutoff_response_time_value);

                    item.color = "black";
                    if (item.sla_info !== null) {
                        // now check the time since sent against the appropriate flag
                        //console.log("This item's sla id is: ", item.sla_info.id);
                        // get the correct time since sent value for warning
                        let warningTimeValueToCompare;
                        let targetTimeValueToCompare;

                        switch (item.sla_info.warning_cutoff_response_time_unit) {
                            case "Minutes":
                                warningTimeValueToCompare = item.minutes_since_sent;
                                break;
                            case "Hours":
                                warningTimeValueToCompare = item.hours_since_sent;
                                break;
                            case "Days":
                                warningTimeValueToCompare = item.days_since_sent;
                                break;
                            case "Business Days":
                                warningTimeValueToCompare = item.business_days_since_sent;
                                break;
                            default:
                                warningTimeValueToCompare = item.business_hours_since_sent;
                        }

                        switch (item.sla_info.target_response_time_unit) {
                            case "Minutes":
                                targetTimeValueToCompare = item.minutes_since_sent;
                                break;
                            case "Hours":
                                targetTimeValueToCompare = item.hours_since_sent;
                                break;
                            case "Days":
                                targetTimeValueToCompare = item.days_since_sent;
                                break;
                            case "Business Days":
                                targetTimeValueToCompare = item.business_days_since_sent;
                                break;
                            default:
                                targetTimeValueToCompare = item.business_hours_since_sent;
                        }

                        if (targetTimeValueToCompare > item.sla_info.target_response_time_value) {
                            item.color = "red";
                        }

                        else if (warningTimeValueToCompare >= item.sla_info.warning_cutoff_response_time_value && targetTimeValueToCompare <= item.sla_info.target_response_time_value) {
                            item.color = "darkOrange";
                        }

                        else {
                            item.color = "green";
                        }

                    }
                }
            }

            // if responded time was NOT null but this ticket is not solved yet, we need to compare the RESOLUTION times
            else if (item.date_time_solved === null) {
                item.sla_info = null;
                if (item.sla_id !== null) {
                    item.sla_info = this.state.ticket_slas.find(e => e.id === item.sla_id);

                    //console.log("This ticket's sla warning cutoff values is: ", item.sla_info.warning_cutoff_response_time_value);

                    item.color = "black";
                    if (item.sla_info !== null) {
                        // now check the time since sent against the appropriate flag
                        //console.log("This item's sla id is: ", item.sla_info.id);
                        // get the correct time since sent value for warning
                        let warningTimeValueToCompare;
                        let targetTimeValueToCompare;

                        switch (item.sla_info.warning_cutoff_resolution_time_unit) {
                            case "Minutes":
                                warningTimeValueToCompare = item.minutes_since_sent;
                                break;
                            case "Hours":
                                warningTimeValueToCompare = item.hours_since_sent;
                                break;
                            case "Days":
                                warningTimeValueToCompare = item.days_since_sent;
                                break;
                            case "Business Days":
                                warningTimeValueToCompare = item.business_days_since_sent;
                                break;
                            default:
                                warningTimeValueToCompare = item.business_hours_since_sent;
                        }

                        switch (item.sla_info.target_resolution_time_unit) {
                            case "Minutes":
                                targetTimeValueToCompare = item.minutes_since_sent;
                                break;
                            case "Hours":
                                targetTimeValueToCompare = item.hours_since_sent;
                                break;
                            case "Days":
                                targetTimeValueToCompare = item.days_since_sent;
                                break;
                            case "Business Days":
                                targetTimeValueToCompare = item.business_days_since_sent;
                                break;
                            default:
                                targetTimeValueToCompare = item.business_hours_since_sent;
                        }

                        if (targetTimeValueToCompare > item.sla_info.target_resolution_time_value) {
                            item.color = "red";
                        }

                        else if (warningTimeValueToCompare >= item.sla_info.warning_cutoff_resolution_time_value && targetTimeValueToCompare <= item.sla_info.target_resolution_time_value) {
                            item.color = "darkOrange";
                        }

                        else {
                            item.color = "green";
                        }

                    }
                }
            }

            // otherwise, this ticket has been responded to AND resolved so just use black color
            else {
                item.color = "black";
            }

            //  console.log("Item info after time since sent loop", item);

        }
    }

    getAssignedTickets() {
        const offsetValue = this.state.getTicketMode === 1 ? 0 : this.state.ticketOffsetValue

        // clear and then reset the timer for autorefresh since we're currently pulling in new tickets
        clearTimeout(this.timer);

        this.timer = setTimeout(() => {

            this.setState({ getTicketMode: 1 }, () => this.autoRefresh())

            //this.autoRefresh()    


        }, 60000);

        // console.log("Values being sent to database for search: ");
        // console.log(this.props.loggedStatus.accessToken);
        // console.log("offset value: ", offsetValue);
        // console.log("status name: ", this.props.myAssignedTickets.status_name);
        // console.log(this.props.myAssignedTickets.type_name); 
        // console.log("assignment id: ", this.props.myAssignedTickets.active_assignment_id);
        // console.log("priority id: ", this.props.myAssignedTickets.priority_id);
        // console.log("search text: ", this.state.searchText); 
        // console.log(this.state.sortOrder); 
        // console.log("logged in user id: ", this.props.loggedStatus.id);
        MyAssignedTicketsService.getAssignedTickets(this.props.loggedStatus.accessToken, offsetValue, this.props.myAssignedTickets.status_name, this.props.myAssignedTickets.type_name, this.props.myAssignedTickets.active_assignment_id_assigned_tickets_filter, this.props.myAssignedTickets.priority_id, this.state.searchText, this.state.sortOrder, this.state.sortBy, this.props.loggedStatus.id)
            .then(response => {
                console.log("Response from getAssignedTickets", response);

                // Set the time since created info
                this.setTicketTimesSinceCreated(response.data.results);

                if (response.data.results && this.state.getTicketMode === 1) {
                    const newTickets = response.data.results.filter(ticket => {
                        if (ticket.ticket_type) {
                            return this.state.tickets.filter(x => x.id === ticket.id && x.ticket_type.trim() === ticket.ticket_type.trim()).length === 0
                        }
                        else {
                            return this.state.tickets.filter(x => x.id === ticket.id).length === 0
                        }
                        // return this.state.tickets.filter(x => x.id === ticket.id && x.ticket_type.trim() === ticket.ticket_type.trim()).length === 0
                    })


                    console.log('new tickets autorefresh', newTickets)
                    this.setState({
                        tickets: [...newTickets, ...this.state.tickets],
                        getTicketMode: 0,
                        ticketOffsetValue: this.state.ticketOffsetValue + newTickets.length     // we added new tickets, so the offset has increased slightly
                    })
                }

                else if (response.data.results && this.state.getTicketMode === 2) {        // if this is first grab, reset the tickets in array  and advance offset by 10
                    window.scrollTo(0, 0)
                    this.setState({
                        tickets: response.data.results,
                        ticketOffsetValue: 10, //this.state.ticketOffsetValue + 10,
                        rowCount: response.data.rowCount,        // Total number of possible rows for those search parameters
                        getTicketMode: 0
                    });
                }



                else if (response.data.results && this.state.getTicketMode === 0) {      // this is not the first grab (got here through a scroll) so add latest results to current ones
                    this.setState(prevState => ({
                        tickets: [...prevState.tickets, ...response.data.results]
                    }));

                    this.setState({
                        ticketOffsetValue: this.state.ticketOffsetValue + 10,
                        rowCount: response.data.rowCount,
                        scrollUpdateComplete: true
                    });
                }

            })
            .catch(e => {
                console.log(e);
            });
    }

    handleSortOrderSwap() {

        if (this.state.sortOrder === "desc") {       // if currently descending order, change to asc
            console.log("Currently desc, switching to asc");

            this.setState({
                sortOrder: "asc",
                ticketOffsetValue: 0,        // new search criteria so set the value back to 0
                getTicketMode: 2        // first grab
            }, () => this.getAssignedTickets());
        }

        else {      // otherwise, we were ordering by asc, so switch to desc
            console.log("Currently asc, switching to desc");
            this.setState({
                sortOrder: "desc",
                ticketOffsetValue: 0,        // new search criteria, so set the value back to 0
                getTicketMode: 2
            }, () => this.getAssignedTickets());
        }
    }


    handleAssignedTableScroll = () => {
        if (window.scrollY === 0) {
            this.setState({ scrollUpdateComplete: true })
        }
        const bottom = window.scrollY + window.innerHeight >= document.body.scrollHeight + 10 && window.scrollY > 100;
        if (bottom) {

            if (this.state.ticketOffsetValue < this.state.rowCount && this.state.scrollUpdateComplete) {        // if we haven't gotten all the tickets yet, ask for the next 10
                this.setState({ scrollUpdateComplete: false })
                this.getAssignedTickets();
            }
        }
    }

    // gets called after 1.5 second timeout after user types something into the search bar
    // since search criteria has changed, user gets a new list with a starting offset value
    resetTicketOffset() {
        if (window.history.replaceState) {
            var queryParameters = new URLSearchParams(window.location.search);
            queryParameters.set("search", this.state.searchText);
            var paramStringList = [];
            for (const [key, value] of queryParameters.entries()) {
                if (value.length > 0) {
                    paramStringList.push(key + "=" + value);
                }
            }
            console.log(paramStringList.join("&"));
            const paramString = paramStringList.join("&");
            if (paramString.length > 0) {
                window.history.replaceState({}, null, "myAssignedTickets?" + paramString);
            } else {
                window.history.replaceState({}, null, "myAssignedTickets");
            }
        }
        this.setState({
            ticketOffsetValue: 0,
            getTicketMode: 2
        }, () => this.getAssignedTickets());
    }

    // using arrow notation in onChangeSearchText so we can reference this.state within the internal timeout function call
    // function will execute a search after user has stopped typing for 1.5 seconds
    onChangeSearchText = (e) => {
        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }

        if (this.state.resetOffsetTimeout) {
            clearTimeout(this.state.resetOffsetTimeout);
        }

        this.setState({
            searchText: e.target.value,

            resetOffsetTimeout: setTimeout(() => {
                this.resetTicketOffset();   // reset the ticket offset in state back to 0 (they have changed their search criteria so new list)
            }, 1500),

            typingTimeout: setTimeout(() => {
                //this.getAssignedTickets();
                // if(this.state.selectedFilterTicketCategory === "All Categories"){    // user has no category selected but is searching        
                //     this.searchKnowledgebase(this.state.searchText, 0, this.state.sortTicketsBy, this.state.ticketSortOrder, this.props.loggedStatus.accessToken);    // we know offset is 0 so manually sending through (when they change search criteria, the list of tickets shown resets)
                // }

                // else if(this.state.searchText === "") {    // User emptied search bar, but still has category selected
                //     this.retrieveFilteredTickets(this.state.selectedFilterTicketCategoryID, 0, this.state.sortTicketsBy, this.state.ticketSortOrder, this.props.loggedStatus.accessToken).then(response => { // we know offset is 0 so manually sending through (when they change search criteria, the list of tickets shown resets)
                //         this.setState({     // save the new tickets and inform state that we asked for first 10
                //             tickets: response.results,
                //             rowCount: response.row_count,   // TOTAL number of tickets possible for those search params
                //             ticketOffsetValue: 10
                //         });
                //     })
                //     .catch(e => {
                //         console.log(e);
                //     });
                // }

                // else {  // User has both category and search bar letters
                //     this.searchKnowledgebaseByCategory(this.state.searchText, 0, this.state.selectedFilterTicketCategory, this.state.sortTicketsBy, this.state.ticketSortOrder, this.props.loggedStatus.accessToken); // we know offset is 0 so manually sending through (when they change search criteria, the list of tickets shown resets)
                // }
            }, 1500)
        }, () => this.props.UPDATE_SEARCH_TEXT(e.target.value));
    }

    autoRefresh() {
        this.props.onAccessTokenRequest(this.props.loggedStatus.username).then((token) => {

            // if the token can't be refreshed, force a logout
            if (token === undefined || token === null || token === "") {
                console.log("Token was expired and can't refresh, so force logging out this user!");
                this.props.LOGOUT();        // logout of global state
                this.props.onLogout(this.props.loggedStatus.username);      // logout from Microsoft
            }

            else {
                this.props.ACCESSTOKEN(token);
                HardwareTicketDataService.getSlas(token).then(response => {

                    this.setState({
                        ticket_slas: response.data.results,
                    });
                    this.getAssignedTickets()

                    console.log("SLA data: ", this.state.ticket_slas);
                })
            }
        });

        // this.timer = setTimeout(() => {

        //     this.setState({ getTicketMode: 1 }, () => this.autoRefresh())

        //     //this.autoRefresh()    


        // }, 60000)
    }

    resetFilters() {
        this.props.RESET_FILTERS();
        this.setState({
            searchText: "",
            sortBy: "date",
            sortOrder: "asc",
            reverseSortDirection: true,
            tickets: [],
            ticketOffsetValue: 0,
            getTicketMode: 2
        }, () => this.getAssignedTickets());
    }

    render() {

        if (!this.props.loggedStatus.loggedIn) {
            return (
                <Redirect to='/' />
            );
        }

        const rows = this.state.tickets.map((ticket) => (
            <Table.Tr key={`${ticket.ticket_type}-${ticket.id}`} onClick={() => {
                if (this.props.myAssignedTickets.type_name === "Hardware") {
                    this.props.history.push(`/hardwareTickets/${ticket.id}`);
                } else if (this.props.myAssignedTickets.type_name === "Software") {
                    this.props.history.push(`/softwareTickets/${ticket.id}`);
                } else if (this.props.myAssignedTickets.type_name === "Unclarified") {
                    this.props.history.push(`/clarifyTicket/${ticket.id}`);
                } else if (this.props.myAssignedTickets.type_name === "All" && ticket.ticket_type === "Hardware") {
                    this.props.history.push(`/hardwareTickets/${ticket.id}`);
                } else if (this.props.myAssignedTickets.type_name === "All" && ticket.ticket_type === "Unclarified") {
                    this.props.history.push(`/clarifyTicket/${ticket.id}`);
                }
            }}>
                <Table.Td>{this.props.myAssignedTickets.type_name === "Hardware" &&
                    <div className="gothamNarrowFont" style={{ color: "var(--mantine-color-blue-5)" }}>{ticket.ticket_title}</div>
                }
                    {this.props.myAssignedTickets.type_name === "Software" &&
                        <div className="gothamNarrowFont" style={{ color: "var(--mantine-color-blue-5)" }}>{ticket.ticket_title}</div>
                    }
                    {this.props.myAssignedTickets.type_name === "Unclarified" &&
                        <div className="gothamNarrowFont" style={{ color: "var(--mantine-color-blue-5)" }}>{ticket.ticket_title}</div>
                    }
                    {this.props.myAssignedTickets.type_name === "All" && ticket.ticket_type === "Hardware" &&
                        <div className="gothamNarrowFont" style={{ color: "var(--mantine-color-blue-5)" }}>{ticket.ticket_title}</div>
                    }
                    {this.props.myAssignedTickets.type_name === "All" && ticket.ticket_type === "Unclarified" &&
                        <div className="gothamNarrowFont" style={{ color: "var(--mantine-color-blue-5)" }}>{ticket.ticket_title}</div>
                    }
                    {this.props.myAssignedTickets.type_name === "Unclarified" || (this.props.myAssignedTickets.type_name === "All" && ticket.ticket_type === "Unclarified") ?
                        <div className="gothamNarrowLightFont">{`Temporary # ${ticket.id}`}</div>
                        :
                        <div className="gothamNarrowLightFont">{`Ticket # ${ticket.id}`}</div>
                    }
                    {ticket.ticket_category && this.props.myAssignedTickets.type_name !== "Unclarified" ?
                        <div className="gothamNarrowLightFont">{ticket.ticket_category}</div>
                        :
                        <div className="gothamNarrowLightFont">Unprocessed</div>
                    }
                    {ticket.assigned_tech_name !== null ?
                        <div className="gothamNarrowLightFont">{ticket.assigned_tech_name}</div>
                        :
                        <div className="gothamNarrowLightFont" style={{ color: "red" }}>Unassigned</div>
                    }</Table.Td>
                <Table.Td>{ticket.resolution_status}</Table.Td>
                {ticket.sla_name ?
                    <Table.Td>{ticket.sla_name}</Table.Td>
                    :
                    <Table.Td>Unset</Table.Td>
                }
                {this.props.myAssignedTickets.type_name !== "All" ?
                    <Table.Td>{this.props.myAssignedTickets.type_name === "Hardware" ? "Tech Support" : "Unprocessed"}</Table.Td>
                    :
                    ticket.ticket_type === "Hardware" ?
                        <Table.Td>Tech Support</Table.Td>
                        :
                        <Table.Td>Unprocessed</Table.Td>
                }
                {ticket.asker_name === "Unregistered Unregistered" ?
                    <Table.Td><div>Unregistered</div><div>{`(${ticket.name_of_tenant})`}</div></Table.Td> :
                    <Table.Td>{ticket.asker_name.length > 25 ? <div><div>{`${ticket.asker_name.substring(0, 25)}...`}</div><div>{`(${ticket.name_of_tenant})`}</div></div> : <div><div>{ticket.asker_name}</div><div>{`(${ticket.name_of_tenant})`}</div></div>}</Table.Td>
                }
                {ticket.color ?
                    <Table.Td style={{ color: `${ticket.color}` }}>{ticket.time_since_sent}</Table.Td>
                    :
                    <Table.Td>{ticket.time_since_sent}</Table.Td>
                }
            </Table.Tr>
        ))

        return (
            <>
                <Navbar pageTitle={this.props.userPermissions.can_manage_assignments ? "Ticket Management" : "My Assigned Tickets"} />
                <div style={{ height: "15px" }} />
                <Container fluid >
                    <Paper withBorder p="md" radius="md" display="block" m="5px">
                        <Flex
                            justify="flex-start"
                            align="flex-start"
                            direction="row"
                            gap="md"
                            h="90px"
                        >
                            <StatusSelector />
                            <PrioritySelector onAccessTokenRequest={this.props.onAccessTokenRequest} />
                            <TypeSelector />
                            <AssignedTechSelector onAccessTokenRequest={this.props.onAccessTokenRequest} />
                            <div style={{ marginTop: "32px" }}>
                                <Tooltip label="Reset Filters"><Button variant="light" color="red" onClick={() => this.resetFilters()}><IconFilterX /></Button></Tooltip>
                            </div>
                        </Flex>
                        <TextInput
                            placeholder="Search"
                            mb="md"
                            leftSection={<IconSearch style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
                            value={this.state.searchText}
                            onChange={this.onChangeSearchText}
                        />
                        <Table highlightOnHover="true" style={{ cursor: "pointer" }}>
                            <Table.Thead style={{ cursor: "pointer" }}>
                                <Table.Tr>
                                    <Th
                                        sorted={this.state.sortBy === 'title'}
                                        reversed={this.state.reverseSortDirection}
                                        onSort={() => this.handleColumnSort('title')}
                                    >
                                        <div className={this.state.sortBy === "title" ? "activeMyAssignedTicketsHeader" : ""}>Title</div>
                                    </Th>
                                    <Th
                                        sorted={this.state.sortBy === 'status'}
                                        reversed={this.state.reverseSortDirection}
                                        onSort={() => this.handleColumnSort('status')}
                                    >
                                        <div className={this.state.sortBy === "status" ? "activeMyAssignedTicketsHeader" : ""}>Status</div>
                                    </Th>
                                    <Th
                                        sorted={this.state.sortBy === 'priority'}
                                        reversed={this.state.reverseSortDirection}
                                        onSort={() => this.handleColumnSort('priority')}
                                    >
                                        <div className={this.state.sortBy === "priority" ? "activeMyAssignedTicketsHeader" : ""}>Priority</div>
                                    </Th>
                                    <Th
                                        sorted={this.state.sortBy === 'type'}
                                        reversed={this.state.reverseSortDirection}
                                        onSort={() => this.handleColumnSort('type')}
                                    >
                                        <div className={this.state.sortBy === "type" ? "activeMyAssignedTicketsHeader" : ""}>Type</div>
                                    </Th>
                                    <Th
                                        sorted={this.state.sortBy === 'creator'}
                                        reversed={this.state.reverseSortDirection}
                                        onSort={() => this.handleColumnSort('creator')}
                                    >
                                        <div className={this.state.sortBy === "creator" ? "activeMyAssignedTicketsHeader" : ""}>Creator</div>
                                    </Th>
                                    <Th
                                        sorted={this.state.sortBy === 'date'}
                                        reversed={this.state.reverseSortDirection}
                                        onSort={() => this.handleColumnSort('date')}
                                    >
                                        <div className={this.state.sortBy === "date" ? "activeMyAssignedTicketsHeader" : ""}>Time Since Created</div>
                                    </Th>
                                </Table.Tr>
                            </Table.Thead>
                            <Table.Tbody>
                                {rows.length > 0 ? (
                                    rows
                                ) : (
                                    <Table.Tr>
                                        <Table.Td>
                                            <Text fw={500} ta="center">
                                                Nothing found
                                            </Text>
                                        </Table.Td>
                                    </Table.Tr>
                                )}
                            </Table.Tbody>
                        </Table>
                    </Paper>
                </Container>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedStatus: state.logged,
        activeNavLinkStatus: state.activeNav,
        myAssignedTickets: state.myAssignedTickets,
        userPermissions: state.userPermissions
        //getEmails: state.getEmails
    };
}

export default connect(mapStateToProps, { UPDATE_ACTIVE_LINK, ACCESSTOKEN, LOGOUT, ASSIGNMENT_CHANGE_RESOLVED, STATUS_CHANGE_RESOLVED, TYPE_CHANGE_RESOLVED, PRIORITY_CHANGE_RESOLVED, RESET_FILTERS, UPDATE_ACTIVE_ASSIGNMENT, UPDATE_SEARCH_TEXT, UPDATE_SORT_BY, UPDATE_SORT_ORDER })(MyAssignedTickets);
