import React, { Component } from "react";
// import AccountDataService from "../services/account-activities";
// import { Redirect } from "react-router-dom";

import { connect } from 'react-redux';
// import { ACCESSTOKEN } from '../features/actions/Is-Logged-Actions';
// import { UPDATE_ACTIVE_LINK } from '../features/actions/Active-Nav-Link-Actions';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Motion, spring } from "react-motion";
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import HardwareAssetDataService from '../../services/hardware-asset-activities';
import { SET_DASHBOARD_TENANT_VIEWING } from '../../features/actions/Is-Logged-Actions';

import { RingProgress, Text, SimpleGrid, Paper, Center, Group, rem } from '@mantine/core';
import {
    Progress,
    Box,
    Table,
    TextInput,
    Flex,
    Button,
    Space,
    Grid,
    keys,
    UnstyledButton,
    Container,
} from '@mantine/core';

import Card from 'react-bootstrap/Card';

import { Link } from 'react-router-dom';

import Navbar from '../../components/Navbar';

import ListGroup from 'react-bootstrap/ListGroup';
//import dashboardActivities from "../../services/dashboard-activities";
import { MdFollowTheSigns } from "react-icons/md";
import { Doughnut, Line } from "react-chartjs-2";

//import ChartWidget from '../Chart-Widget';       // WILL END UP BEING SOMETHING DIFFERENT FOR ADMIN

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Chart.js Line Chart',
        },
    },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];


class AdminDashboard extends Component {

    // componentDidMount() {
    //     this.props.UPDATE_ACTIVE_LINK("add_account");
    // }

    // onChangeName(e) {
    //     this.setState({
    //         name: e.target.value
    //     });
    // }

    constructor(props) {
        super(props);

        this.state = {
            totalTickets: 0,
            unresolvedTickets: 0,
            tenants: [],     // [{id: 1, name_of_tenant: "LPA"}]
            line: {
                labels,
                datasets: [
                    {
                        label: 'Dataset 1',
                        data: [],
                        borderColor: 'rgb(255, 99, 132)',
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    },
                    {
                        label: 'Dataset 2',
                        data: [],
                        borderColor: 'rgb(53, 162, 235)',
                        backgroundColor: 'rgba(53, 162, 235, 0.5)',
                    },
                ],
            },
            data: {
                labels: ['Piority 1', 'Priority 2', 'Priority 3', 'Priority 4', 'Priority 5'],
                datasets: [
                    {
                        label: '# of Votes',
                        data: [12, 19, 3, 5, 2],
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                        ],
                        borderWidth: 1,
                    },
                ],
            }
        };
    }

    // saveAccount() {
    //     var data = {
    //         name: this.state.name
    //     };

    //     if(this.props.loggedStatus.loggedIn){
    //         this.props.onAccessTokenRequest(this.props.loggedStatus.username).then((token) => {
    //         this.props.ACCESSTOKEN(token);
    //         AccountDataService.create(data, token)
    //         .then(response => {
    //             this.props.history.push('/accounts');
    //         //console.log(response.data);
    //         // this.setState({
    //         //     redirect: true
    //         // });
    //         })
    //         .catch(e => {
    //             console.log(e);
    //         });
    //         });
    //     }

    componentDidMount() {
        /* dashboardActivities.getUnresolvedTicketCount(this.props.loggedStatus.accessToken, this.props.loggedStatus.id).then(response => {
            this.setState({ unresolvedTickets: response.data.results.unresolvedTickets, totalTickets: response.data.results.totalTickets })
        }); */

        if (this.props.loggedStatus.loggedIn) {
            this.props.onAccessTokenRequest(this.props.loggedStatus.username).then((token) => {

                HardwareAssetDataService.getTenants(token, this.props.loggedStatus.id).then(response => {
                    console.log(response);
                    this.setState({
                        tenants: response.data.results
                    }, () => {
                        //this.setReportTenant(this.state.tenants);   // update the global state with the tenant selected

                        // if this is first time landing on this page, we need to update the global state and local one
                        if (this.props.loggedStatus.dashboard_tenant_name_selected === "") {

                            if (response.data.results && response.data.results.length > 0) {
                                this.props.SET_DASHBOARD_TENANT_VIEWING({ dasboard_tenant_id_selected: response.data.results[0].id, dashboard_tenant_name_selected: response.data.results[0].name_of_tenant });
                                this.setState({
                                    selectedTenant: response.data.results[0].name_of_tenant,
                                    selectedTenantId: response.data.results[0].id,
                                    cursor: (this.state.selectedPageButton - 1) * this.state.resultsPerPage
                                }, () => this.autoRefresh());
                            }
                        }

                        else {
                            // we've landed on this page before, so set the local state to the global one

                            this.setState({
                                selectedTenant: this.props.loggedStatus.dashboard_tenant_name_selected,
                                selectedTenantId: this.props.loggedStatus.dashboard_tenant_id_selected,
                                cursor: (this.state.selectedPageButton - 1) * this.state.resultsPerPage
                            }, () => this.autoRefresh());
                        }
                    });
                })
                    .catch(e => {
                        console.log(e);
                    });

            });
        }
    }

    handleDashboardTenantChange(name_of_tenant) {

        // if this is a new selection, update the global state
        if (name_of_tenant !== this.state.selectedTenant) {
            let newTenantId = this.state.tenants.find(o => o.name_of_tenant === name_of_tenant).id;
            this.props.SET_DASHBOARD_TENANT_VIEWING({ dashboard_tenant_id_selected: newTenantId, dashboard_tenant_name_selected: name_of_tenant });

            // this is new tenant selection so ask for data
            this.setState({
                selectedTenant: name_of_tenant,
                selectedTenantId: newTenantId,
                cursor: 0,       // reset the cursor to the backend
                selectedPageButton: 1   // reset the page selected
            }, () => this.autoRefresh());
        }
    }

    // this fxn currently makes sure token is fresh and calls getTaniumChartData - not currently on auto timer, but may change
    autoRefresh() {
        console.log("auto refresh dashboard");
        this.props.onAccessTokenRequest(this.props.loggedStatus.username).then((token) => {
        });

        // uncomment this to turn auto refresh back on
        /*this.timer = setTimeout(() => {

            this.autoRefresh()

        }, 60000)*/
    }



    // AccountDataService.create(data)
    // .then(response => {
    //     //console.log(response.data);
    //     // this.setState({
    //     //     redirect: true
    //     // });
    // })
    // .catch(e => {
    //     console.log(e);
    // });
    //}


    render() {
        // if(!this.props.loggedStatus.loggedIn) {
        //     return (
        //         <Redirect to='/'/>
        //     );
        // }

        // const { redirect } = this.state;

        // if(redirect) {
        //     return <Redirect to='/accounts'/>   // To redirect back after submission
        // }

        return (
            <>
                <Navbar pageTitle={"User Home"} />
                <div className="container mt-3" style={{ paddingTop: "15px" }}></div>
                {/* <div style={{position: "relative", backgroundColor: "pink"}}> */}
                {/* <div className="smallCumulusLogoContainer"> */}
                {/* <img src="Cumulus_Logo.png" alt="server room" style={{width: "10%", float: "right"}}/> */}
                {/* </div> */}
                {/* </div> */}
                {/* <div style={{paddingTop: "20px", paddingLeft: "10px"}}>
                        <img src="Cumulus_Logo.png" alt="server room" style={{width: "33%"}}/>
                </div> */}
                {/* <span className="smallCumulusLogoContainer"><img src="Cumulus_Logo.png" alt="server room" style={{width: "10%", float: "right"}}/></span> */}
                <Container fluid>
                    <Col md={{ span: 8, offset: 2 }}>
                        <div style={{ textAlign: "center", marginLeft: "auto", marginRight: "auto", alignContent: "center" }}>
                            <Card bg="dark" style={{ border: "none", width: "50%", marginRight: "auto", marginLeft: "auto" }}>
                                <Card.Header style={{ color: "white", textAlign: "center" }} className="gothamNarrowFont cardHeader">Admin Functions</Card.Header>
                                <ListGroup variant="flush" style={{ textAlign: "center" }}>
                                    {/*this.props.userPermissions.can_see_users || this.props.userPermissions.can_edit_users || this.props.userPermissions.can_add_users || this.props.userPermissions.can_delete_users ?
                                            <Link to="/userList" className="popular-activities-link-text"><ListGroup.Item className="popular-activities-table-row gothamNarrowLightFont">Current User List / Assignments</ListGroup.Item></Link>
                                            : undefined
                                        */}
                                    {/*this.props.userPermissions.can_see_assets || this.props.userPermissions.can_edit_assets || this.props.userPermissions.can_add_assets || this.props.userPermissions.can_delete_assets ?
                                            <Link to="/hardwareAssets" className="popular-activities-link-text"><ListGroup.Item className="popular-activities-table-row gothamNarrowLightFont">Hardware Assets</ListGroup.Item></Link>
                                            : undefined
                                        */}
                                    {/*this.props.userPermissions.can_see_assets || this.props.userPermissions.can_edit_assets || this.props.userPermissions.can_add_assets || this.props.userPermissions.can_delete_assets ?
                                            <Link to="/softwareAssets" className="popular-activities-link-text"><ListGroup.Item className="popular-activities-table-row gothamNarrowLightFont">Software Assets</ListGroup.Item></Link>
                                            : undefined
                                        */}
                                    <Link to="/hardwareTickets" className="popular-activities-link-text"><ListGroup.Item className="popular-activities-table-row gothamNarrowLightFont">Tech Support Tickets</ListGroup.Item></Link>
                                    {/* <Link to="/softwareTickets" className="popular-activities-link-text"><ListGroup.Item className="popular-activities-table-row gothamNarrowLightFont">Software Tickets</ListGroup.Item></Link> */}
                                </ListGroup>
                            </Card>
                        </div>
                    </Col>
                    {/*<Paper withBorder p="md" radius="md" w="100%" mb="sm" display="inline-block">
                        <Flex
                            justify="flex-start"
                            align="flex-start"
                            direction="row"
                            gap="md"
                        >
                            {this.state.tenants && this.state.tenants.map((tenant) => (
                                <Button
                                    h="36px"
                                    variant={this.state.selectedTenant === tenant.name_of_tenant ? "filled" : "default"}
                                    onClick={() => this.handleDashboardTenantChange(tenant.name_of_tenant)}
                                >
                                    {tenant.name_of_tenant}
                                </Button>))}
                        </Flex>
                    </Paper>
                    <Grid gutter="sm">
                        <Grid.Col span={6}>
                            <Paper withBorder radius="md" p="15px" key={"lowDiskSpace"} w="100%" mb="10px" display="inline-block" style={{ verticalAlign: "top" }}>
                                <Text>Unresolved Tickets by Priority</Text>
                                <Space m="20px" />
                                <Doughnut data={this.state.data} />
                            </Paper>
                            <Paper withBorder radius="md" p="15px" key={"lowDiskSpace"} w="100%" mb="10px" display="inline-block" style={{ verticalAlign: "top" }}>
                                <Text>Unresolved Tickets by Date</Text>
                                <Space m="20px" />
                                <Line options={options} data={this.state.line} />
                            </Paper>
                        </Grid.Col>
                    </Grid>
                    {/*<DragDropContext>
                        <Droppable droppableId="dnd-list" direction="horizontal">
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef} style={{display: "flex"}}>
                                    <Draggable key={"1"} index={0} draggableId={"1"}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={{width: "400px", display: "flex", ...provided.draggableProps.style}}
                                            >
                                                <Paper withBorder radius="md" p="15px" key={"lowDiskSpace"} h="115px" w="400px" mb="10px" display="inline-block" style={{ verticalAlign: "top" }}>
                                                    <Group>
                                                        <Motion defaultStyle={{ x: 0 }} style={{ x: spring((this.state.unresolvedTickets / (this.state.totalTickets === 0 ? 1 : this.state.totalTickets)) * 100) }}>
                                                            {({ x }) => <RingProgress
                                                                size={80}
                                                                roundCaps
                                                                thickness={8}
                                                                sections={[{ value: x, color: ((this.state.unresolvedTickets / (this.state.totalTickets === 0 ? 1 : this.state.totalTickets)) > 0.8 ? "red" : "blue") }]}
                                                                label={
                                                                    <Center>
                                                                        {Math.round((this.state.unresolvedTickets / (this.state.totalTickets === 0 ? 1 : this.state.totalTickets)) * 100)}%
                                                                    </Center>
                                                                }
                                                            />
                                                            }
                                                        </Motion>

                                                        <div>
                                                            <Text c="dimmed" size="xs" tt="uppercase" fw={700}>
                                                                {"unresolved tickets"}
                                                            </Text>
                                                            <Text fw={700} size="xl">
                                                                {this.state.unresolvedTickets}
                                                            </Text>
                                                        </div>
                                                    </Group>
                                                </Paper>
                                            </div>
                                        )}
                                    </Draggable>
                                    <Draggable key={"2"} index={1} draggableId={"2"}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={{width: "400px", display: "flex"}}
                                            >
                                                <Paper withBorder radius="md" p="15px" key={"lowDiskSpace"} h="115px" w="400px" mb="10px" display="inline-block" style={{ verticalAlign: "top" }}>
                                                    <Group>
                                                        <Motion defaultStyle={{ x: 0 }} style={{ x: spring((this.state.unresolvedTickets / (this.state.totalTickets === 0 ? 1 : this.state.totalTickets)) * 100) }}>
                                                            {({ x }) => <RingProgress
                                                                size={80}
                                                                roundCaps
                                                                thickness={8}
                                                                sections={[{ value: x, color: ((this.state.unresolvedTickets / (this.state.totalTickets === 0 ? 1 : this.state.totalTickets)) > 0.8 ? "red" : "blue") }]}
                                                                label={
                                                                    <Center>
                                                                        {Math.round((this.state.unresolvedTickets / (this.state.totalTickets === 0 ? 1 : this.state.totalTickets)) * 100)}%
                                                                    </Center>
                                                                }
                                                            />
                                                            }
                                                        </Motion>

                                                        <div>
                                                            <Text c="dimmed" size="xs" tt="uppercase" fw={700}>
                                                                {"unresolved tickets"}
                                                            </Text>
                                                            <Text fw={700} size="xl">
                                                                {this.state.unresolvedTickets}
                                                            </Text>
                                                        </div>
                                                    </Group>
                                                </Paper>
                                            </div>
                                        )}
                                    </Draggable>
                                    <Draggable key={"3"} index={2} draggableId={"3"}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={{width: "400px", display: "flex"}}
                                            >
                                                <Paper withBorder radius="md" p="15px" key={"lowDiskSpace"} h="115px" w="400px" mb="10px" display="inline-block" style={{ verticalAlign: "top" }}>
                                                    <Group>
                                                        <Motion defaultStyle={{ x: 0 }} style={{ x: spring((this.state.unresolvedTickets / (this.state.totalTickets === 0 ? 1 : this.state.totalTickets)) * 100) }}>
                                                            {({ x }) => <RingProgress
                                                                size={80}
                                                                roundCaps
                                                                thickness={8}
                                                                sections={[{ value: x, color: ((this.state.unresolvedTickets / (this.state.totalTickets === 0 ? 1 : this.state.totalTickets)) > 0.8 ? "red" : "blue") }]}
                                                                label={
                                                                    <Center>
                                                                        {Math.round((this.state.unresolvedTickets / (this.state.totalTickets === 0 ? 1 : this.state.totalTickets)) * 100)}%
                                                                    </Center>
                                                                }
                                                            />
                                                            }
                                                        </Motion>

                                                        <div>
                                                            <Text c="dimmed" size="xs" tt="uppercase" fw={700}>
                                                                {"unresolved tickets"}
                                                            </Text>
                                                            <Text fw={700} size="xl">
                                                                {this.state.unresolvedTickets}
                                                            </Text>
                                                        </div>
                                                    </Group>
                                                </Paper>
                                            </div>
                                        )}
                                    </Draggable>
                                    <Draggable key={"4"} index={3} draggableId={"4"}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={{width: "400px", display: "flex"}}
                                            >
                                                <Paper withBorder radius="md" p="15px" key={"lowDiskSpace"} h="115px" w="400px" mb="10px" display="inline-block" style={{ verticalAlign: "top" }}>
                                                    <Group>
                                                        <Motion defaultStyle={{ x: 0 }} style={{ x: spring((this.state.unresolvedTickets / (this.state.totalTickets === 0 ? 1 : this.state.totalTickets)) * 100) }}>
                                                            {({ x }) => <RingProgress
                                                                size={80}
                                                                roundCaps
                                                                thickness={8}
                                                                sections={[{ value: x, color: ((this.state.unresolvedTickets / (this.state.totalTickets === 0 ? 1 : this.state.totalTickets)) > 0.8 ? "red" : "blue") }]}
                                                                label={
                                                                    <Center>
                                                                        {Math.round((this.state.unresolvedTickets / (this.state.totalTickets === 0 ? 1 : this.state.totalTickets)) * 100)}%
                                                                    </Center>
                                                                }
                                                            />
                                                            }
                                                        </Motion>

                                                        <div>
                                                            <Text c="dimmed" size="xs" tt="uppercase" fw={700}>
                                                                {"unresolved tickets"}
                                                            </Text>
                                                            <Text fw={700} size="xl">
                                                                {this.state.unresolvedTickets}
                                                            </Text>
                                                        </div>
                                                    </Group>
                                                </Paper>
                                            </div>
                                        )}
                                    </Draggable>
                                    <Draggable key={"5"} index={4} draggableId={"5"}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={{width: "400px", display: "flex"}}
                                            >
                                                <Paper withBorder radius="md" p="15px" key={"lowDiskSpace"} h="115px" w="400px" mb="10px" display="inline-block" style={{ verticalAlign: "top" }}>
                                                    <Group>
                                                        <Motion defaultStyle={{ x: 0 }} style={{ x: spring((this.state.unresolvedTickets / (this.state.totalTickets === 0 ? 1 : this.state.totalTickets)) * 100) }}>
                                                            {({ x }) => <RingProgress
                                                                size={80}
                                                                roundCaps
                                                                thickness={8}
                                                                sections={[{ value: x, color: ((this.state.unresolvedTickets / (this.state.totalTickets === 0 ? 1 : this.state.totalTickets)) > 0.8 ? "red" : "blue") }]}
                                                                label={
                                                                    <Center>
                                                                        {Math.round((this.state.unresolvedTickets / (this.state.totalTickets === 0 ? 1 : this.state.totalTickets)) * 100)}%
                                                                    </Center>
                                                                }
                                                            />
                                                            }
                                                        </Motion>

                                                        <div>
                                                            <Text c="dimmed" size="xs" tt="uppercase" fw={700}>
                                                                {"unresolved tickets"}
                                                            </Text>
                                                            <Text fw={700} size="xl">
                                                                {this.state.unresolvedTickets}
                                                            </Text>
                                                        </div>
                                                    </Group>
                                                </Paper>
                                            </div>
                                        )}
                                    </Draggable>
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                                                        </DragDropContext>*/}
                </Container>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedStatus: state.logged,
        activeNavLinkStatus: state.activeNav,
        userPermissions: state.userPermissions
    };
}

export default connect(mapStateToProps, { SET_DASHBOARD_TENANT_VIEWING })(AdminDashboard);